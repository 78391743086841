<script>
/**
 * Navbar component
 */
export default {
  data() {
    return {
      isCondensed: false,
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
  },

  mounted: () => {
    window.onscroll = function () {
      onwindowScroll();
    };

    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }

      // if (
      //   document.body.scrollTop > 100 ||
      //   document.documentElement.scrollTop > 100
      // ) {
      //   document.getElementById("back-to-top").style.display = "inline";
      // } else {
      //   document.getElementById("back-to-top").style.display = "none";
      // }
    }

    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
  },
};
</script>

<template>
  <div>
    <!-- Navbar STart -->
    <header
      id="topnav"
      class="defaultscroll sticky"
      :class="{ 'bg-white': isWhiteNavbar === true }"
    >
      <div class="container">
        <!-- Logo container-->
        <div>
          <router-link class="logo" to="/" v-if="navLight !== true">
            <img src="images/TreepLogo.png" height="50" alt="TREEP Logo" />
          </router-link>
          <router-link class="logo" to="/" v-else>
            <img src="images/TreepLogo.png" class="l-dark" height="50" alt="TREEP Logo" />
            <img
              src="images/TreepLogo_1.png"
              class="l-light"
              height="40"
              alt="TREEP Logo"
            />
          </router-link>
        </div>
        <div class="buy-button" v-if="isIcons !== true">
          <span>
            <a href="https://treep.cc/demo/?channelType=1" target="_blank" class="help_text" :class="{'nav_light': navLight}">Help</a>
          </span>
          <a href="https://new.treep.cc/#/light-openTrial"
            target="_blank"
            class="btn"
            :class="{
              'btn-light': navLight === true,
              'btn-primary': navLight !== true,
            }"
            >Start free trial</a>
        </div>
        <!--end login button-->
        <!--end login button-->
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a
              class="navbar-toggle"
              @click="toggleMenu()"
              :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul
            class="navigation-menu"
            :class="{ 'nav-light': navLight === true }"
          >
            <li>
              <router-link to="/" class="side-nav-link-ref">Home</router-link>
            </li>
            <li class="has-submenu">
              <a href="javascript:void(0)" @click="onMenuClick">System</a
              ><span class="menu-arrow"></span>
              <ul class="submenu">
                <li>
                  <router-link to="/pms" class="side-nav-link-ref"
                    >PMS: Property Management System</router-link
                  >
                </li>
                <li>
                  <router-link to="/channelmanager" class="side-nav-link-ref"
                    >Channel Manager</router-link
                  >
                </li>
                <li>
                  <router-link to="/bookingengine" class="side-nav-link-ref"
                    >Booking Engine</router-link
                  >
                </li>
                <li>
                  <router-link to="/agentportal" class="side-nav-link-ref"
                    >Agent Portal</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/marketing" class="side-nav-link-ref">Marketing</router-link>
            </li>
            <li>
              <router-link to="/price" class="side-nav-link-ref">Price</router-link>
            </li>
            
          </ul>
          <!--end navigation menu-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->
  </div>
</template>

<style scoped>
.help_text{
  margin-right: 20px;
  color: #3c4858 ;
}
.nav_light{
  color: rgba(255, 255, 255, 0.5) !important;
}
.nav-sticky .nav_light{
  color: #3c4858 !important;
}
</style>
